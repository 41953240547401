import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { get, post } from '@aws-amplify/api';
import { Amplify } from 'aws-amplify';
// Keep the old methods from aws-amplify/storage
import { uploadData, getUrl, remove } from 'aws-amplify/storage';

import config from '../amplifyconfiguration.json';
import {
  FiMenu,
  FiMoreVertical,
  FiSearch,
  FiThumbsUp,
  FiThumbsDown,
  FiEdit
} from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

Amplify.configure(config);

const ChatPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // The Amplify user from LandingPage
  const { user } = location.state || {};

  // UI-friendly display name
  const displayName =
    user?.attributes?.name ||
    user?.attributes?.preferred_username ||
    user?.username ||
    'Guest';

  // The unique user ID
  const userId = user?.username || 'Guest';

  // States for sidebar, menu, search, etc.
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const [input, setInput] = useState('');

  // Chat data
  const [chats, setChats] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);

  // Bot typing
  const [botTyping, setBotTyping] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);

  // Dislike overlay
  const [dislikeOptionsOpen, setDislikeOptionsOpen] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [feedbackState, setFeedbackState] = useState({});

  const messagesEndRef = useRef(null);

  // Toggling the search bar
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Amplify API references
  const apiName = 'sovattapi';
  const path = '/chat';

  // Dislike reasons
  const reasons = [
    { id: 'inaccurate', label: 'Inaccurate' },
    { id: 'unhelpful', label: 'Unhelpful' },
    { id: 'incomplete', label: 'Incomplete' },
  ];

  // ==================================================================
  //  readJsonFile & writeJsonFile => getUrl + fetch, uploadData
  // ==================================================================
  /**
   * readJsonFile: read existing JSON array from S3.  
   * - We use getUrl({ path, level: 'public' }) to get a presigned URL.  
   * - If HTTP 200 => parse JSON.  
   * - If 404 => return [].  
   * - Otherwise => return [] & log error.  
   */
  async function readJsonFile(s3Path) {
    try {
      // Must specify level: 'public' if stored in the public folder
      const fileUrl = await getUrl({ path: s3Path, level: 'public' });
      console.log(`Presigned URL => ${fileUrl}`);
      const response = await fetch(fileUrl);

      if (response.ok) {
        const text = await response.text();
        return JSON.parse(text);
      } else if (response.status === 404) {
        console.log('No content.json found, returning []');
        return [];
      } else {
        console.log(`Fetch error => ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (err) {
      console.log(`Error fetching JSON at ${s3Path}:`, err);
      return [];
    }
  }

  /**
   * writeJsonFile: overwrites entire JSON file at s3Path with dataArray.  
   * - Using uploadData({ path, data, contentType, level: 'public' })
   */
  async function writeJsonFile(s3Path, dataArray) {
    const jsonString = JSON.stringify(dataArray, null, 2);
    await uploadData({
      path: s3Path,
      data: jsonString,
      contentType: 'application/json',
      level: 'public' // store in 'public' folder
    });
  }

  // ================
  //  Digestor logic
  // ================
  const callDigestor = useCallback(async () => {
    try {
      const payload = {
        body: {
          userId,
          chats,
          activeChatId,
          timestamp: new Date().toISOString(),
        },
      };
      console.log('Calling digestor with ephemeral data:', payload);
      await post({ apiName, path: '/digestor', options: payload });
    } catch (err) {
      console.error('Digestor error:', err);
    }
  }, [userId, chats, activeChatId, apiName, path]);

  // =========================================================
  //  useEffects => fetch chats, inactivity, tab close, etc.
  // =========================================================
  useEffect(() => {
    const fetchAllChats = async () => {
      try {
        const response = await get({ apiName, path });
        if (response && response.chats) {
          if (response.chats.length > 0) {
            setChats(response.chats);
            setActiveChatId(response.chats[0].chatId);
          } else {
            createNewChat();
          }
        } else {
          createNewChat();
        }
      } catch (error) {
        console.error('Error fetching chats:', error);
        createNewChat();
      }
    };
    fetchAllChats();
  }, [apiName, path]);

  // Auto-scroll to bottom
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chats, botTyping]);

  // Close the dislike overlay if user clicks outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dislikeOptionsOpen !== null &&
        !event.target.closest('.dislike-options')
      ) {
        setDislikeOptionsOpen(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dislikeOptionsOpen]);

  // Close top-right menu if user clicks outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuOpen]);

  // Inactivity => call digestor
  useEffect(() => {
    let inactivityTimer;
    const INACTIVITY_LIMIT = 5 * 60 * 1000; // 5 min

    const resetTimer = () => {
      if (inactivityTimer) clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        console.log('User inactive => calling digestor');
        callDigestor();
      }, INACTIVITY_LIMIT);
    };

    const events = ['mousemove', 'keydown', 'click', 'touchstart'];
    events.forEach((evt) => window.addEventListener(evt, resetTimer));
    resetTimer();

    return () => {
      events.forEach((evt) => window.removeEventListener(evt, resetTimer));
      if (inactivityTimer) clearTimeout(inactivityTimer);
    };
  }, [callDigestor, chats, activeChatId]);

  // Tab close => call digestor
  useEffect(() => {
    const handleTabClose = () => {
      console.log('Tab closing => calling digestor');
      callDigestor();
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [callDigestor, chats, activeChatId]);

  // ================================
  //  Create new chat
  // ================================
  const createNewChat = () => {
    const newChatId = uuidv4();
    const newChat = {
      chatId: newChatId,
      title: 'New Chat',
      messages: [],
    };
    setChats((prev) => [...prev, newChat]);
    setActiveChatId(newChatId);
  };

  // Identify the active chat
  const activeChat = chats.find((c) => c.chatId === activeChatId);
  const messages = activeChat?.messages || [];

  // ================================
  // Send user message
  // ================================
  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim() || !activeChatId) return;

    const newUserMessage = {
      senderName: displayName,
      senderId: userId,
      content: input,
      timestamp: new Date().toISOString(),
    };

    // Insert message locally
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.chatId === activeChatId
          ? { ...chat, messages: [...chat.messages, newUserMessage] }
          : chat
      )
    );

    // If it's a brand new chat => rename with user's first msg
    if (activeChat?.title === 'New Chat') {
      const firstMsg = input.substring(0, 50).trim();
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.chatId === activeChatId
            ? { ...chat, title: firstMsg || 'Untitled Chat' }
            : chat
        )
      );
    }

    setInput('');

    try {
      // POST to your chat endpoint
      const payload = {
        body: {
          sender: userId,
          content: input,
          timestamp: new Date().toISOString(),
          chatId: activeChatId,
        },
      };
      const response = await post({ apiName, path: '/chat/messages', options: payload });
      const resolvedResponse = await response.response;
      const bodyText = await resolvedResponse.body.text();
      const parsedResponse = JSON.parse(bodyText);
      const finalResponse = parsedResponse.response
        ? JSON.parse(parsedResponse.response)
        : null;

      if (finalResponse && finalResponse.message) {
        simulateBotResponse(finalResponse.message);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // ================================
  // Bot "typing" simulation
  // ================================
  const simulateBotResponse = (botMessage) => {
    if (!botMessage?.content || !activeChatId) return;

    const botFullContent = botMessage.content;
    let currentIndex = 0;

    setBotTyping('');
    setIsBotTyping(true);

    const typingInterval = setInterval(() => {
      if (currentIndex < botFullContent.length) {
        setBotTyping((prev) => prev + botFullContent[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setIsBotTyping(false);

        // Insert final bot message
        setChats((prevChats) =>
          prevChats.map((chat) => {
            if (chat.chatId === activeChatId) {
              const newBotMessage = {
                senderName: 'Sovatt Bot',
                senderId: 'SovattBotID',
                content: botFullContent,
                timestamp: new Date().toISOString(),
                isFinal: true,
              };
              return {
                ...chat,
                messages: [...chat.messages, newBotMessage],
              };
            }
            return chat;
          })
        );
      }
    }, 40);
  };

  // ================================
  //  Feedback: like vs. dislike
  // ================================
  const handleLike = (index) => {
    // Toggle "like" visually
    setFeedbackState((prev) => ({
      ...prev,
      [index]: prev[index] === 'like' ? null : 'like',
    }));
    setDislikeOptionsOpen(null);
  };

  const openDislikeOptions = (index) => {
    // Toggle "dislike" visually
    setFeedbackState((prev) => ({
      ...prev,
      [index]: prev[index] === 'dislike' ? null : 'dislike',
    }));
    setDislikeOptionsOpen(index);
    setSelectedReasons([]);
  };

  const toggleReason = (reasonId) => {
    setSelectedReasons((prev) =>
      prev.includes(reasonId)
        ? prev.filter((r) => r !== reasonId)
        : [...prev, reasonId]
    );
  };

  /**
   * Submit feedback => read "like" or "dislike" content.json,
   * append a new object with userId, chatId, etc., then write back.
   */
  const submitFeedback = async (index) => {
    if (!activeChat) return;

    // determine like vs. dislike
    const isDislike = feedbackState[index] === 'dislike';
    // single content.json for each
    const key = isDislike
      ? 'feedback/dislike/content.json'
      : 'feedback/like/content.json';

    const newEntry = {
      userId, // we add userId
      chatId: activeChatId,
      timestamp: new Date().toISOString(),
      unprocessedMessages: messages.slice(index),
    };
    if (isDislike) {
      newEntry.reasons = selectedReasons;
    }

    try {
      // read existing array from content.json
      const existingArray = await readJsonFile(key);

      // push new item
      existingArray.push(newEntry);

      // write entire file
      await writeJsonFile(key, existingArray);

      console.log(`Feedback appended to ${key}`, newEntry);

      // close overlay
      setDislikeOptionsOpen(null);
      setSelectedReasons([]);
    } catch (error) {
      console.error('Error appending feedback:', error);
    }
  };

  // Switch chat
  const handleChatSelect = (chatId) => {
    setActiveChatId(chatId);
  };

  // Simple search filter
  const filteredChats = chats.filter((chat) =>
    chat.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // format time
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Sign out => call digestor, then signOut
  const handleSignOut = async () => {
    console.log('User signing out => calling digestor first...');
    await callDigestor();
    try {
      await signOut();
      navigate('/landing');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  // ================================
  // Render
  // ================================
  return (
    <div className="flex h-screen bg-gray-100 relative">
      {/* SIDEBAR */}
      <div
        className={`w-64 bg-gray-900 text-white fixed lg:relative h-full z-40 ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 transition-transform ease-in-out duration-300`}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <div className="flex items-center space-x-3">
            <h2 className="text-xl font-semibold">Chats</h2>
            <FiEdit
              className="text-xl cursor-pointer"
              onClick={createNewChat}
            />
          </div>
          <FiSearch
            className="text-xl cursor-pointer"
            onClick={() => setShowSearch((prev) => !prev)}
          />
        </div>

        {showSearch && (
          <div className="p-4 border-b border-gray-700">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search chats..."
              className="w-full px-3 py-2 text-sm rounded focus:outline-none text-gray-800"
            />
          </div>
        )}

        <div
          className="p-4 space-y-2 overflow-y-auto"
          style={{ maxHeight: 'calc(100% - 120px)' }}
        >
          {filteredChats.map((chat) => (
            <p
              key={chat.chatId}
              onClick={() => handleChatSelect(chat.chatId)}
              className={`p-3 rounded-lg cursor-pointer ${
                activeChatId === chat.chatId
                  ? 'bg-gray-700'
                  : 'bg-gray-800 hover:bg-gray-700'
              }`}
            >
              {chat.title}
            </p>
          ))}
        </div>
      </div>

      {/* Overlay for mobile sidebar */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={() => setSidebarOpen(false)}
        />
      )}

      {/* MAIN CHAT */}
      <div className="flex flex-col flex-1">
        {/* Top Bar */}
        <header className="flex justify-between items-center p-4 bg-white shadow-md">
          <FiMenu
            className="text-2xl cursor-pointer lg:hidden"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          />
          <h2 className="text-xl font-semibold">{displayName}</h2>
          <div className="relative" ref={menuRef}>
            <FiMoreVertical
              className="text-2xl cursor-pointer"
              onClick={() => setMenuOpen(!menuOpen)}
            />
            {menuOpen && (
              <div className="absolute right-0 bg-white shadow-lg rounded-md mt-2 w-48 z-50">
                <p
                  onClick={handleSignOut}
                  className="p-4 cursor-pointer hover:bg-gray-100"
                >
                  Sign Out
                </p>
                <p className="p-4 text-gray-400">Credits (Coming Soon)</p>
              </div>
            )}
          </div>
        </header>

        {/* Messages */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {!activeChatId && (
            <div className="text-gray-600">
              <p>No chat selected. Create a new chat or choose from the list.</p>
            </div>
          )}

          {activeChatId &&
            messages.map((msg, index) => {
              const isBot = msg.senderName === 'Sovatt Bot';
              return (
                <div
                  key={index}
                  className={`flex flex-col ${
                    isBot ? 'items-start' : 'items-end'
                  }`}
                >
                  <div
                    className={`p-3 rounded-lg max-w-lg ${
                      isBot
                        ? 'bg-gray-100 text-gray-800'
                        : 'bg-gray-900 text-white'
                    }`}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {msg.content}
                    <div
                      className={`text-xs mt-1 ${
                        isBot ? 'text-gray-600' : 'text-gray-200'
                      }`}
                      style={{ textAlign: 'right' }}
                    >
                      {msg.timestamp}
                    </div>
                  </div>

                  {/* Feedback for final Bot messages */}
                  {isBot && msg.isFinal && (
                    <div className="flex items-center space-x-4 mt-2">
                      <FiThumbsUp
                        className={`text-xl cursor-pointer ${
                          feedbackState[index] === 'like'
                            ? 'text-green-500'
                            : 'text-gray-400'
                        }`}
                        onClick={() => handleLike(index)}
                      />
                      <FiThumbsDown
                        className={`text-xl cursor-pointer ${
                          feedbackState[index] === 'dislike'
                            ? 'text-red-500'
                            : 'text-gray-400'
                        }`}
                        onClick={() => openDislikeOptions(index)}
                      />
                    </div>
                  )}

                  {/* Dislike reason overlay */}
                  {dislikeOptionsOpen === index && (
                    <div className="dislike-options p-4 bg-white rounded-lg shadow-lg mt-2 border border-gray-200 w-80">
                      <h3 className="text-lg font-bold text-center text-gray-800 mb-4">
                        Why was this unhelpful?
                      </h3>
                      <ul className="space-y-3">
                        {reasons.map((reason) => (
                          <li
                            key={reason.id}
                            className={`p-3 flex items-center rounded-md cursor-pointer transition-all border ${
                              selectedReasons.includes(reason.id)
                                ? 'bg-gray-300 border-gray-500 text-gray-800'
                                : 'bg-gray-100 hover:bg-gray-200 text-gray-700 border-gray-300'
                            }`}
                            onClick={() => toggleReason(reason.id)}
                          >
                            <div
                              className={`w-5 h-5 border-2 flex items-center justify-center mr-3 rounded ${
                                selectedReasons.includes(reason.id)
                                  ? 'bg-gray-600 border-gray-600'
                                  : 'border-gray-400'
                              }`}
                            >
                              {selectedReasons.includes(reason.id) && (
                                <span className="text-white font-bold">✓</span>
                              )}
                            </div>
                            {reason.label}
                          </li>
                        ))}
                      </ul>
                      <div className="flex justify-center mt-5">
                        <button
                          className={`px-5 py-2 rounded-lg text-white ${
                            selectedReasons.length
                              ? 'bg-gray-700 hover:bg-gray-800'
                              : 'bg-gray-400 cursor-not-allowed'
                          }`}
                          onClick={() => submitFeedback(index)}
                          disabled={!selectedReasons.length}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

          {/* Bot "typing" bubble */}
          {isBotTyping && (
            <div className="flex justify-start">
              <div
                className="p-3 rounded-lg bg-gray-100 text-gray-800 text-sm max-w-lg"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {botTyping}
              </div>
            </div>
          )}

          <div ref={messagesEndRef} />
        </div>

        {/* Input field */}
        {activeChatId && (
          <form onSubmit={sendMessage} className="p-4 bg-white border-t flex">
            <input
              type="text"
              className="flex-1 p-2 border rounded-lg focus:outline-none text-sm"
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <button
              type="submit"
              className="ml-4 px-4 py-2 bg-black text-white rounded-lg text-sm"
            >
              Send
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
